import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import AppImage from 'common/assets/image/crypto/app.svg';
import Image from 'common/components/Image';
import PlaystoreImage from 'common/assets/image/crypto/playstore.svg';
import LogoImage from 'common/assets/image/AppClassic/logo.png';
import FooterArea, {
  WidgetArea,
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
} from './footer.style';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        }
      }
    }
  `);
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterWrapper id="footerSection">
      <Container>

        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="https://app.h2zero.bm"
              logoSrc={LogoImage}
              title="H2Zero Bermuda"
              logoStyle={logoStyle}
            />
            <Text content="h2zerobm@gmail.com" {...textStyle} />
            <Text content="+1-441-335-1013" {...textStyle} />
            <Heading
              className="appDownload"
              content="Download The App"
              {...titleStyle}
            />
            <Box className="imageWrapper">
              <a href="https://apps.apple.com/us/app/h2zero/id1602519505">
                <Image src={AppImage} alt="App Image" />
              </a>
              <a href="https://play.google.com/store/apps">
                <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
              </a>
              
            </Box>
          </Box>

          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.agencyJson.menuWidget.map((widget) => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item) => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a className="ListItem" href={item.url}>
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}

        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
